import { Request } from '@/http/request'

export function blackNumberList(parameter: any){
  return Request.axiosInstance.post('/open/black-number/list', parameter)
}
export function blackNumberAdd(parameter: any){
  return Request.axiosInstance.post('/open/black-number/add', parameter)
}
export function blackNumberDelete(parameter: any){
  return Request.axiosInstance.post('/open/black-number/delete', parameter)
}
export function AblackNumberlist(parameter: any){
  return Request.axiosInstance.post('/open/black-tel-a/list', parameter)
}
export function AblackNumberdelete(parameter: any){
  return Request.axiosInstance.post('/open/black-tel-a/delete', parameter)
}
export function getListBlack(parameter: any){
  return Request.axiosInstance.post('/open/voice-check/get', parameter)
}
export function getListBlacklist(parameter: any){
  return Request.axiosInstance.post('/open/black-telA-check/list', parameter)
}
export default { blackNumberList, blackNumberAdd, blackNumberDelete, AblackNumberlist, AblackNumberdelete, getListBlack,getListBlacklist }