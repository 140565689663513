// 语音质检-语音类型
import { Request } from "@/http/request"

// 语音添加
export function voiceAdd(parameter: any) {
  return Request.axiosInstance.post('/open/voice-type/add', parameter)
}

// 语音删除
export function voiceDelete(parameter: any) {
  return Request.axiosInstance.post('/open/voice-type/delete', parameter)
}

// 语音修改
export function voiceEdit(parameter: any) {
  return Request.axiosInstance.post('/open/voice-type/edit', parameter)
}

// 语音列表
export function voiceQuery(parameter: any) {
  return Request.axiosInstance.post('/open/voice-type/query', parameter)
}

// 语音列表
export function voiceList(parameter: any) {
  return Request.axiosInstance.post('/open/voice-type/list', parameter)
}

// 查询单个
export function voiceSingle(parameter: any) {
  return Request.axiosInstance.post('/open/voice-type/get', parameter)
}

// 语音质检类型下拉列表
export function voiceName(parameter: any) {
  return Request.axiosInstance.post('/open/voice-type/query-name', parameter)
}

// 语音质检类型下拉列表
export function addBlackA(parameter: any) {
  return Request.axiosInstance.post('/open/black-tel-a/add', parameter)
}
// 主叫号码批量添加
export function batchAddition(parameter: any) {
  return Request.axiosInstance.post('/open/black-tel-a/add-batch', parameter)
}
// 被叫黑名单批量添加
export function batchAdditionBlacklist(parameter: any) {
  return Request.axiosInstance.post('/open/black-number/add-batch', parameter)
}
// 批量添加主叫号码
export function additionBatch(parameter: any) {
  return Request.axiosInstance.post('/open/black-tel-a/add-batch-plus', parameter)
}
// 批量添加被叫黑名单
export function additionBlacklistBatch(parameter: any) {
  return Request.axiosInstance.post('/open/black-number/add-batch-plus', parameter)
}
//用户行业下拉接口
export function userIndustry(parameter: any) {
  return Request.axiosInstance.post('/open/voice-vos-user-industry/query-by-uid', parameter)
}
//批量添加接口 /open/black-telA-check/batch-delete
export function batchSave(parameter: any) {
  return Request.axiosInstance.post('/open/black-telA-check/batch-save', parameter)
}
//批量删除接口 
export function batchDelete(parameter: any) {
  return Request.axiosInstance.post('/open/black-telA-check/batch-delete', parameter)
}
export default {  userIndustry, additionBatch, additionBlacklistBatch, batchAdditionBlacklist, voiceAdd, voiceDelete, voiceEdit, voiceQuery, voiceSingle, voiceList, voiceName, addBlackA, batchAddition,batchSave,batchDelete }