// 黑名单
<template>
  <div class="menu">
    <div class="screening">
      <el-dialog
        title="查询"
        v-model="theQueryDialog"
        :close-on-click-modal="false"
        :destroy-on-close="true"
        width="600px"
      >
        <el-form
          label-position="left"
          label-width="100px"
          size="small"
          :model="searchFrom"
          ref="upform"
        >
          <el-form-item label="手机号码">
            <el-input
              v-model="searchFrom.telA"
              class="input-searchFrom-width"
              placeholder="请输入需要查询的主叫号码"
              size="mini"
            >
            </el-input>
          </el-form-item>

          <el-form-item label="禁用时间">
            <el-date-picker
              v-model="valueTime"
              type="datetimerange"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              clearable
              value-format="YYYY-MM-DD HH:mm:ss"
              size="mini"
              :default-time="defaultTime"
            >
            </el-date-picker>
          </el-form-item>

          <el-form-item>
            <div class="flex-nowrap-flex-end">
              <el-button class="cancel" plain @click="reset" round>
                重置
              </el-button>
              <el-button class="confirmAdd" @click="onSubmit" round>
                查询
              </el-button>
            </div>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
    <el-row>
      <el-col :span="12"> </el-col>
      <el-col :span="12">
        <div class="title-buttom">
          <div class="ml-10">
            <el-button
              icon="el-icon-search"
              class="button-el"
              size="medium"
              @click="theQueryDialog = true"
              >查询
            </el-button>
          </div>
          <div class="ml-10">
            <el-button class="button-el" size="medium" @click="batchDelete()">
              <i class="el-icon-minus" />
              批量删除
            </el-button>
          </div>
          <div class="ml-10">
            <el-button
              class="button-el"
              size="medium"
              @click="DialogBatchaddVisible = true"
            >
              <i class="iconfont icon-tianjia1" />
              批量新增
            </el-button>
          </div>
          <div class="ml-10">
            <el-tooltip content="刷新列表" placement="top" effect="light">
              <div
                class="font-refresh-out flex-nowrap-center-center"
                @click="reset(1)"
              >
                <icon-font
                  v-if="loading == false"
                  type="icon-shuaxin"
                  class="font-refresh"
                />
                <i v-else class="el-icon-loading loding-color"></i>
              </div>
            </el-tooltip>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <div>
          <el-table
            class="eltable"
            v-loading="loading"
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%"
            size="mini"
            :row-style="{ height: '50px' }"
            :cell-style="{ padding: '0px' }"
            :header-cell-style="{ background: '#e0e5ff', color: '#24252F' }"
            row-key="id"
            default-expand-all
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column label="用户id" v-if="userType == 'system'">
              <template #default="scope">{{
                scope.row.uid == null || String(scope.row.uid) === ""
                  ? "-"
                  : scope.row.uid
              }}</template>
            </el-table-column>
            <el-table-column label="号码">
              <template #default="scope">{{
                scope.row.telA == null || scope.row.telA == ""
                  ? "-"
                  : scope.row.telA
              }}</template>
            </el-table-column>
            <el-table-column label="创建时间">
              <template #default="scope">{{
                scope.row.createTime == null || scope.row.createTime == ""
                  ? "-"
                  : scope.row.createTime
              }}</template>
            </el-table-column>
            <el-table-column label="操作" width="180">
              <template #default="scope">
                <el-tooltip content="删除用户" placement="top" effect="light">
                  <el-button
                    class="deletetable"
                    size="mini"
                    icon="el-icon-delete"
                    round
                    @click="confirmEvent(scope.row)"
                  ></el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>
    </el-row>
    <div class="pager">
      <div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          background
          :page-sizes="[10, 20, 30, 40]"
          :page-size="searchFrom.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>

    <div class="popup-view">
      <!-- 批量添加黑名单模态框 -->
      <el-dialog
        title="批量添加黑名单 (重复的黑名单号码会自动过滤)"
        v-model="DialogBatchaddVisible"
        width="35%"
        :close-on-click-modal="false"
        :destroy-on-close="true"
        @close="closeDialog"
      >
        <div class="center-body addBlack">
          <div class="m3-flex-col m3-margin-top-m">
            手机号:
            <el-input
              v-model="batchForm.phoneString"
              type="textarea"
              :autosize="{ minRows: 6 }"
              placeholder="请输入手机号码(输入多个号码时请换行输入)"
            >
            </el-input>
          </div>
        </div>
        <template #footer>
          <div>
            <el-button plain @click="handleBatchCancle()">取消</el-button>
            <el-button type="primary" @click="handleBatchAdd">添加</el-button>
          </div>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import blackNumber from "@/api/open/black/blackNumber";
import voiceType from "@/api/open/voice/voiceType";
import { IconFont } from "@/utils/iconfont";
import _lineService from "@/api/open/LineQualityInspections";

export default {
  components: {
    IconFont,
  },
  data() {
    const checkNumber = (rule, value, callback) => {
      let num = 0,
        types = null,
        errorItem;
      if (value == null) {
        callback(new Error("请填写号码 (多个号码请换行输入)"));
      } else if (value.length == 0) {
        callback(new Error("请填写号码 (多个号码请换行输入)"));
      } else {
        let newArr = [];
        newArr = value.split(/[(\r\n)\r\n]+/);
        newArr.forEach((item, i) => {
          if (
            item.match(
              /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9]|9[0-9]|0[0-9][0-9]|2[0-9])\d{8}$/
            ) == null
          ) {
            // if(item.match(/^(1[3|4|5|6|7|8|9])\\d{9}$|^0\\d{2,3}-?\\d{7,8}$|^9\\d{9}/) == null){
            // callback(new Error('请输入正确的号码 (请检查第'+(i+1)+"行)"))
            num++;
            types = i;
            errorItem = item;
          }
        });
        if (num != 0) {
          callback(
            new Error(
              "请输入正确的号码 (请检查第" +
                (types + 1) +
                "行：" +
                errorItem +
                ")"
            )
          );
        } else {
          callback();
        }
      }
    };
    const checkPhone = (rule, value, callback) => {
      var reg = /^1[3456789]\d{9}$/;
      if (!reg.test(value)) {
        callback(new Error("请输入正确的手机号码格式"));
      } else if (value == null || value == "") {
        callback(new Error("手机号码不能为空"));
      } else {
        callback();
      }
    };
    return {
      //批量添加黑名单
      batchForm: {
        phoneString: "",
        phone: [],
        remark: null,
        amountId: "0",
        reason: null,
      },

      tableHeight: "",
      searchFrom: {
        page: 1,
        pageSize: 10,
        createTimeBegin: "",
        createTimeEnd: "",
        telA: "",
      },
      telType: "0",
      telTypeRadio: "0",
      batchTelTypeRadio: "0", //批量
      detailsDialog: false,
      disableDetails: {},
      theQueryDialog: false,
      total: 0,
      currentPage: 1, //分页
      multipleSelection: [], //选中集合
      allPhone: [],
      valueTime: [], //选中时间段
      defaultTime: [
        new Date(2000, 1, 1, 8, 0, 0),
        new Date(2000, 2, 1, 23, 59, 59),
      ], //默认时间 '12:00:00', '08:00:00'

      tableData: [], //菜单表
      // loding 状态启用
      loading: true,
      userType: this.$store.state.userType,
      upform: {
        number: null,
      },
      DialogBatchaddVisible: false, //批量新增模态框
      blackTelADtoLists: [
        {
          telA: "",
          disableDuration: null, //null表示没选，’‘空字符串表示永久
          remark: "",
          amountId: "0",
          reason: "",
          hitType: "打人",
        },
      ],
    };
  },
  created() {
    this.tableHeight = this.$store.state.tableHeight;
  },
  watch: {},
  mounted() {
    this.getDataList(true);
  },
  methods: {
    //批量添加黑名单取消
    handleBatchCancle() {
      // this.batchForm.amountId = null;
      // this.batchForm.reason = null
      this.batchForm.phoneString = "";
      // this.batchForm.phone = [];
      // this.batchForm.remark = ''
      this.DialogBatchaddVisible = false;
    },
    //批量添加黑名单添加
    async handleBatchAdd() {
      // 获取输入框的内容并按换行符拆分成手机号码数组
      let arr = this.batchForm.phoneString.split(/\r\n|\r|\n/);

      // 去除首尾空格，并过滤掉空白行
      this.batchForm.phone = [
        ...new Set(arr.map((phoneNumber) => phoneNumber.trim())),
      ].filter((trimmedPhoneNumber) => trimmedPhoneNumber !== "");
      // 手机号码格式验证
      const phoneRegex = /^1[3456789]\d{9}$/; 
      for (const trimmedPhoneNumber of this.batchForm.phone) {
        if (!phoneRegex.test(trimmedPhoneNumber)) {
          this.$notify({
            title: "消息",
            message: `无效的手机号码：${trimmedPhoneNumber}`,
            type: "warning",
          });
          return;
        }
      }

      // 构建请求参数
      let params = {
        phone: this.batchForm.phone,
      };

      // 检查是否有有效的手机号码
      if (this.batchForm.phone.length === 0) {
        this.$notify({
          title: "消息",
          message: "请输入有效的号码！",
          type: "warning",
        });
        return;
      }

      // 调用接口
      voiceType.batchSave(params).then((res) => {
        if (res.code !== 200) {
          this.$message.error(res.message);
          return false;
        } else {
          this.batchForm.phone = [];
          this.$message.success("主叫号码已批量加入黑名单！");
          this.batchForm.phoneString = "";
          this.DialogBatchaddVisible = false;
          this.getDataList(true);
        }
      });
    },

    // 查询
    onSubmit() {
      this.theQueryDialog = false;
      this.getDataList(true);
    },
 // 删除确认
    confirmEvent(row) {
      if (this.telType == "0") {
        this.$confirm("将删除该号码, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          voiceType
          .batchDelete({
              phone:[row.telA]
            })
            .then((res) => {
              this.$notify({
                title: "成功",
                message: "删除成功！",
                type: "success",
              });
              this.getDataList(true);
            });
        });
      } 
      
    },
    // 重置
    reset(kind) {
      this.searchFrom = {
        page: this.currentPage,
        pageSize: 10,
      };
      this.valueTime = [];
      if (kind == 1) {
        this.getDataList(true);
      }
    },
    // 获取数据列表
    getDataList(type) {
      this.loading = type;
      if (this.valueTime != null) {
        this.searchFrom.createTimeBegin = this.valueTime[0];
        this.searchFrom.createTimeEnd = this.valueTime[1];
      } else {
        this.searchFrom.createTimeBegin = "";
        this.searchFrom.createTimeEnd = "";
      }

      blackNumber
        .getListBlacklist({
          ...this.searchFrom,
        })
        .then((res) => {
          this.tableData = res.data.records;
          this.total = res.data.total;
          this.loading = false;
        });
    },
    // 分页
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.searchFrom.pageSize = val;
      this.getDataList(true);
    },
    handleCurrentChange(val) {
      this.searchFrom.page = val;
      this.currentPage = val;
      this.getDataList(true);
    },
    // 多选
    handleSelectionChange(val) {
      let newIds = [];
      val.forEach((item) => {
        newIds.push(item.telA);
      });
      this.allPhone = newIds;
      this.multipleSelection = val;
      // this.multipleSelection = []
      // val.forEach(item => {
      //     this.multipleSelection.push(item.id)
      // });
    },
    //批量删除
    batchDelete() {
      if (this.allPhone.length <= 0) {
        this.$notify({
          title: "消息",
          message: "请勾选要删除的号码！",
          type: "warning",
        });
        return;
      }
      this.$confirm("将删除该号码, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        voiceType
          .batchDelete({
            phone: this.allPhone,
          })
          .then((res) => {
            if (res.code == 200) {
              this.$notify({
                title: "成功",
                message: "批量删除成功！",
                type: "success",
              });
              this.getDataList(true);
            } else {
              this.$notify({
                title: "失败",
                message: res.message || res.msg,
                type: "error",
              });
            }
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.privacy-most {
  height: calc(80vh - 400px);
  overflow-y: overlay;

  .pItem-box {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 10px;

    .input-price-width {
      width: 150px;
    }
  }
}

.menu {
  height: 100%;
  overflow-y: overlay;
  padding-right: 18px;

  .title-name {
    font-size: 0.9375rem;
    font-weight: 600;
    color: #303133;
  }

  .top-first {
    .search-box {
      width: 18.75rem;
    }
  }
}

.screening {
  margin-bottom: 0.9375rem;
}

.el-row {
  margin-bottom: 0.9375rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.title-buttom {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
}

.editor {
  color: #0170ed;
  background: #e3f0ff;
}

// 添加黑名单弹窗
.addBlack {
  height: 100%;
  width: 90%;

  .header {
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.center-body {
  margin: 0 1.875rem;
}

.deletetable {
  color: #ff845b;
  background: #ffe4db;
}

.deletetable-other {
  margin-right: 1.25rem;
}

.confirmAdd {
  background: #637dff;
  color: #fff;
}

.cancel {
  color: #637dff;
  border: #637dff solid 1px;
}

.screening-out {
  margin: 0 0.9375rem;
}

.out-icon:hover {
  background: #e3f0ff;
  color: #0170ed;
}

.pager {
  display: flex;
  justify-content: flex-end;
}

.menu .el-collapse-item__content {
  padding-bottom: 0 !important;
}

.eltable {
  overflow-y: overlay;
}
</style>
